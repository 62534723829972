import {
  Container,
  HeroHeader,
  HeadlineContainer,
  Content,
  MinSpacing,
  Link,
} from "../components/layout-components"
import { graphql } from "gatsby"
import React from "react"
import { asBg } from "../util/image"
import { Columns, TextComponent } from "../components/text-components"
import styled from "styled-components"
import { colors, bp, fonts } from "../components/Styles"
import Footer from "../components/Footer"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"
import FadeLink from "../components/FadeLink"
import { KontaktFooterInner } from "../components/KontaktFooter"
const Spacer = styled.div<{ hide?: boolean }>`
  width: 95%;
  height: 2px;
  margin: 5rem auto 8rem auto;
  content: "";
  background-color: ${({ hide }) =>
    hide ? "transparent" : colors.backgroundLighter};
  opacity: 0.3;

  ${bp("mobile")`
        margin: 3rem auto 5rem auto;
    `}
`

const LinkText = styled.span`
  text-decoration: underline;
  font-family: ${fonts.bold};
`

const List = styled.ul`
  list-style-type: initial;
  margin-top: 2rem;
  margin-bottom: 6rem;

  li {
    margin-left: 2rem;
    padding-left: 1rem;
    margin-bottom: 0.6rem;
  }
`

const Privacy: React.FC<{ data: any }> = ({ data }) => {
  const { headerBackground } = data
  const { t } = useTranslation()

  return (
    <Container>
      <Helmet>
        <title>Garage51 | {t("Datenschutz")}</title>
      </Helmet>

      <HeroHeader {...asBg(headerBackground)} height="30rem">
        <HeadlineContainer align="left">
          <h1>{t("Datenschutz")}</h1>
        </HeadlineContainer>
      </HeroHeader>

      <Content>
        <MinSpacing />
        <Columns
          left={<h3>{t("Hinweise zum Datenschutz")}</h3>}
          right={
            <>
              <TextComponent title="" spacing="small">
                <p>
                  {t(
                    "Wir erheben keinerlei personenbezogene Daten beim Besuch unserer Website und verwenden auch keine Analytics Tools, um die Website-Nutzung zu analysieren. Entsprechend werden auch keine Cookies auf Ihrem Rechner abgelegt."
                  )}
                </p>
              </TextComponent>
            </>
          }
        />

        <Spacer />

        <Columns
          left={<h3>{t("Links zu Websites anderer Anbieter:innen")}</h3>}
          right={
            <>
              <TextComponent title="" spacing="small">
                <p>
                  {t(
                    "Unsere Website kann Links zu Websites anderer Anbieter enthalten. Wir haben uns zum Zeitpunkt der Linksetzung davon überzeugt, dass die direkt verknüpften Seiten frei von illegalen Inhalten waren, haben jedoch keinerlei Einfluss auf die Inhalte der verknüpften Seiten und können diese nicht fortlaufend kontrollieren. Daher übernehmen wir keine Haftung für die Inhalte der verknüpften Seiten, welche nach der Linksetzung verändert wurden. Diese Datenschutzerklärung gilt nicht für die verlinkten Webseiten anderer Anbieter."
                  )}
                </p>
              </TextComponent>
            </>
          }
        />

        <Spacer hide />

        <Columns
          left={<h3>{t("Vorbehalt")}</h3>}
          right={
            <>
              <TextComponent title="" spacing="small">
                <p>
                  {t(
                    "Um der stetigen Entwicklung des Internets gerecht zu werden, kann Garage51 diese Datenschutzerklärung jederzeit unter Beachtung der datenschutzrechtlichen Vorgaben anpassen."
                  )}
                </p>
              </TextComponent>
            </>
          }
        />

        <Spacer hide />

        <Columns
          left={<h3>{t("Verantwortliche und Ansprechpartner")}</h3>}
          right={
            <>
              <TextComponent title={t("Garage51 GmbH")} spacing="small">
                <p>
                  <br />
                  {t("Daimlerstraße 32-36")}
                  <br />
                  {t("60314 Frankfurt")}
                </p>

                <p>
                  {t(
                    "Zur Kontaktaufnahme mit dem Datenschutz-Team oder zur Ausübung von Rechten betroffener Personen nutzen Sie bitte das"
                  )}
                  <br />
                  <Link>
                    <KontaktFooterInner title="Kontaktformular" />
                  </Link>
                </p>
              </TextComponent>
            </>
          }
        />
        <Spacer hide />
      </Content>

      <Footer />
    </Container>
  )
}

export default Privacy

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "privacy"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    headerBackground: file(relativePath: { eq: "slides/Slide_1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, height: 1080)
      }
    }
  }
`
